import React from 'react';
import styled, { css } from 'styled-components';
import scalingSize from '$utils/scalingSize';
import { LinkHeadingProps } from './types';

const StyledHeading = styled.h3<LinkHeadingProps>`
  margin-top: 3.5rem;
  color: var(--color-slate);

  ${(props) =>
    props.large
      ? css`
          ${scalingSize('margin-bottom', 24, 32)};
          ${scalingSize('font-size', 24, 56)};
          letter-spacing: -0.03em;
        `
      : css`
          ${scalingSize('font-size', 16, 24)};
        `}
`;

const Heading: React.FC<LinkHeadingProps> = ({ children, large }) => (
  <StyledHeading large={large}>{children}</StyledHeading>
);

export default Heading;
