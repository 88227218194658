import React from 'react';
import styled, { css } from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import ArrowRight from '$assets/icons/arrow-right.svg';
import scalingSize from '$utils/scalingSize';
import { LinkBlockLinkProps } from './types';
import Markdown from '$components/Markdown';

const StyledButton = styled(AniLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-slate);
  border-radius: 0.5rem;
  box-shadow: 0px 10px 80px rgba(19, 18, 16, 0.04), 0px 10px 33.4221px rgba(19, 18, 16, 0.0287542),
    0px 12.0616px 17.869px rgba(19, 18, 16, 0.0238443), 0px 0px 10.0172px rgba(19, 18, 16, 0.02),
    0px 3.59106px 5.32008px rgba(19, 18, 16, 0.0161557), 0px 1.49432px 2.21381px rgba(19, 18, 16, 0.0112458);
  letter-spacing: -0.02em;
  background-color: var(--color-white);
  max-width: 42.5rem;

  ${(props) =>
    props.large
      ? css`
          margin: 0 0 2rem 0;
          padding: 2rem;
        `
      : css`
          margin: 0 0 0.75rem 0;
          padding: 1rem 1.25rem;
        `}

  svg {
    font-size: 1rem;
    flex-shrink: 0;
    flex-grow: 0;
    stroke: var(--color-citrus);
    fill: none;
    transition: transform 350ms ease;
  }

  p {
    color: var(--color-slate3);
    font-size: 0.875rem;
    margin: 0.5rem 0 0 0;
  }

  h4 {
    margin: 0;
    ${(props) =>
      props.large
        ? css`
            line-height: 2rem;
            ${scalingSize('font-size', 20, 24)};
          `
        : css`
            line-height: 1.3;
            font-size: 1rem;
          `}
  }

  &:hover {
    h4 {
      opacity: 0.8;
    }

    svg {
      transform: translateX(0.25em);
    }
  }
`;

const Link: React.FC<LinkBlockLinkProps> = ({ to, large, title, description }) => (
  <StyledButton to={to} cover bg="var(--color-citrus5)" theme="ghost" large={large ? 1 : 0}>
    <div>
      <h4>{title}</h4>
      {description && (
        <p>
          <Markdown content={description} />
        </p>
      )}
    </div>
    <ArrowRight className="icon" viewBox="0 0 24 24" />
  </StyledButton>
);

export default Link;
