import React from 'react';
import Link from './Link';
import Heading from './Heading';
import { WrapXs } from '$components/Wraps';
import { BlockLinkProps } from './types';
import FadeInSection from '$components/FadeInSection';

const BlockLink: React.FC<BlockLinkProps> = ({ large, heading, linkBlockLinks }) => (
  <WrapXs>
    <FadeInSection>
      <Heading large={large}>{heading}</Heading>
      {linkBlockLinks.map(({ Title, URL, Description }) => (
        <Link key={Title} description={Description} title={Title} large={large} to={URL} />
      ))}
    </FadeInSection>
  </WrapXs>
);

export default BlockLink;
